

function Footer(){
    return(
      <footer className="footer">
        <p className="footer__text">&#169;2024 Around The U.S.</p>
      </footer>
    )
}

export default Footer;